export const enum PlacePriceStatus {
  ENABLED = 1,
  DISABLED = 2,
}

export const enum PublicationFormat {
  post24h = 'post24h',
  post48h = 'post48h',
  post72h = 'post72h',
  fixedInTop = 'fixedInTop',
  postWithoutDelete = 'postWithoutDelete',
  story = 'story',
  repost = 'repost',
}

export const enum PlaceStatus {
  'ENABLED' = 1,
  'DISABED' = 2,
  'BANNED' = 3,
  'ARCHIVE' = 4,
  'DELETED' = 5,
  'ERROR' = 200,
  'DECLINED' = 100,
  'MODERATION_WAIT' = 6,
}

export type PlacePrice = Record<PublicationFormat, number | null>;

type PlaceCommon = {
  name: string;
  url: string;
  price: PlacePrice;
};

export type PlaceCountry = {
  location: string;
  value: number | null;
};

export type PlaceStat = {
  subscribersStatistic: {
    count: number | null;
    malePercent: number;
    monthGrow: number | null;
  };
  viewsStatistic: {
    countLast24h: number | null;
  };
  postsStatistic: {
    countLast24h: number | null;
  };
  dateTime: {
    date: string;
  };
};

export type PlaceStatReduced = {
  posts: number;
  subscribers: number;
  subscribersGrow24h: number;
  subscribersGrowWeek: number;
  subscribersGrowMonth: number;
  views: number;
  date: string;
  er: number;
};

export type ReducedPlace = PlaceCommon & {
  id: number;
  categories: any[];
  er: number;
  subscribersStatistic: {
    count: number | null;
    monthGrow: number | null;
    malePercent: number;
    countries: PlaceCountry[];
    age: { ageRange: string; value: string | null }[] | null;
  };
  viewsStatistic: {
    countLast24h: number | null;
  };
  avatar: string;
  cpm: number;
  clicksCount: number;
  description: string;
  updatedAt: {
    date: string;
  };
  userId: number | null;
};

export type Place = ReducedPlace & {
  private: boolean;
  contact: string;
  status: PlaceStatus;
};

export type ExistingPlace = Place & {
  id: number;
  newPublicationsCount: number;
  ownerUser: {
    id: number;
  } | null;
};

export enum IconColor {
  GREEN = 'rgb(58, 134, 58)',
  ORANGE = 'rgb(234, 138, 34)',
  LIGHT_GRAY = '#D5DCE1',
  GRAY = '#99ABBA',
  SKY = '#00B2FF',
  BRIGHT_SKY = 'rgb(14, 165, 233)',
  BLACK = 'rgb(74, 74, 79)',
  RED = 'rgb(205, 92, 92)',
  WHITE = 'rgb(255,255,255)',
  INDIGO = '#104870',
  SALAD = '#27C2A7',
  PINK = '#ed91f5',
  BLUE = '#49c2f5',
  LIGHT_ORANGE = '#FFB35A',
}

export type VeeValidateFunction<T = string> = (toValidate: T) => string | true;
